// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "geometryVertexDeclaration";
const shader = `uniform mat4 viewProjection;uniform mat4 view;`;
// Sideeffect
if (!ShaderStore.IncludesShadersStore[name]) {
    ShaderStore.IncludesShadersStore[name] = shader;
}
/** @internal */
export const geometryVertexDeclaration = { name, shader };
