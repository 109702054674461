/**
 * Enum of all block names.
 * Note - if you add a new block, you must add it here, and must add it in the block factory!
 */
export const enum FlowGraphBlockNames {
    PlayAnimation = "FlowGraphPlayAnimationBlock",
    StopAnimation = "FlowGraphStopAnimationBlock",
    PauseAnimation = "FlowGraphPauseAnimationBlock",
    ValueInterpolation = "FlowGraphInterpolationBlock",
    SceneReadyEvent = "FlowGraphSceneReadyEventBlock",
    SceneTickEvent = "FlowGraphSceneTickEventBlock",
    SendCustomEvent = "FlowGraphSendCustomEventBlock",
    ReceiveCustomEvent = "FlowGraphReceiveCustomEventBlock",
    MeshPickEvent = "FlowGraphMeshPickEventBlock",
    PointerEvent = "FlowGraphPointerEventBlock",
    PointerDownEvent = "FlowGraphPointerDownEventBlock",
    PointerUpEvent = "FlowGraphPointerUpEventBlock",
    PointerMoveEvent = "FlowGraphPointerMoveEventBlock",
    PointerOverEvent = "FlowGraphPointerOverEventBlock",
    PointerOutEvent = "FlowGraphPointerOutEventBlock",
    E = "FlowGraphEBlock",
    PI = "FlowGraphPIBlock",
    Inf = "FlowGraphInfBlock",
    NaN = "FlowGraphNaNBlock",
    Random = "FlowGraphRandomBlock",
    Add = "FlowGraphAddBlock",
    Subtract = "FlowGraphSubtractBlock",
    Multiply = "FlowGraphMultiplyBlock",
    Divide = "FlowGraphDivideBlock",
    Abs = "FlowGraphAbsBlock",
    Sign = "FlowGraphSignBlock",
    Trunc = "FlowGraphTruncBlock",
    Floor = "FlowGraphFloorBlock",
    Ceil = "FlowGraphCeilBlock",
    Round = "FlowGraphRoundBlock",
    Fraction = "FlowGraphFractBlock",
    Negation = "FlowGraphNegationBlock",
    Modulo = "FlowGraphModuloBlock",
    Min = "FlowGraphMinBlock",
    Max = "FlowGraphMaxBlock",
    Clamp = "FlowGraphClampBlock",
    Saturate = "FlowGraphSaturateBlock",
    MathInterpolation = "FlowGraphMathInterpolationBlock",
    Equality = "FlowGraphEqualityBlock",
    LessThan = "FlowGraphLessThanBlock",
    LessThanOrEqual = "FlowGraphLessThanOrEqualBlock",
    GreaterThan = "FlowGraphGreaterThanBlock",
    GreaterThanOrEqual = "FlowGraphGreaterThanOrEqualBlock",
    IsNaN = "FlowGraphIsNaNBlock",
    IsInfinity = "FlowGraphIsInfBlock",
    DegToRad = "FlowGraphDegToRadBlock",
    RadToDeg = "FlowGraphRadToDegBlock",
    Sin = "FlowGraphSinBlock",
    Cos = "FlowGraphCosBlock",
    Tan = "FlowGraphTanBlock",
    Asin = "FlowGraphASinBlock",
    Acos = "FlowGraphACosBlock",
    Atan = "FlowGraphATanBlock",
    Atan2 = "FlowGraphATan2Block",
    Sinh = "FlowGraphSinhBlock",
    Cosh = "FlowGraphCoshBlock",
    Tanh = "FlowGraphTanhBlock",
    Asinh = "FlowGraphASinhBlock",
    Acosh = "FlowGraphACoshBlock",
    Atanh = "FlowGraphATanhBlock",
    Exponential = "FlowGraphExponentialBlock",
    Log = "FlowGraphLogBlock",
    Log2 = "FlowGraphLog2Block",
    Log10 = "FlowGraphLog10Block",
    SquareRoot = "FlowGraphSquareRootBlock",
    CubeRoot = "FlowGraphCubeRootBlock",
    Power = "FlowGraphPowerBlock",
    Length = "FlowGraphLengthBlock",
    Normalize = "FlowGraphNormalizeBlock",
    Dot = "FlowGraphDotBlock",
    Cross = "FlowGraphCrossBlock",
    Rotate2D = "FlowGraphRotate2DBlock",
    Rotate3D = "FlowGraphRotate3DBlock",
    Transpose = "FlowGraphTransposeBlock",
    Determinant = "FlowGraphDeterminantBlock",
    InvertMatrix = "FlowGraphInvertMatrixBlock",
    MatrixMultiplication = "FlowGraphMatrixMultiplicationBlock",
    BitwiseAnd = "FlowGraphBitwiseAndBlock",
    BitwiseOr = "FlowGraphBitwiseOrBlock",
    BitwiseXor = "FlowGraphBitwiseXorBlock",
    BitwiseNot = "FlowGraphBitwiseNotBlock",
    BitwiseLeftShift = "FlowGraphBitwiseLeftShiftBlock",
    BitwiseRightShift = "FlowGraphBitwiseRightShiftBlock",
    LeadingZeros = "FlowGraphLeadingZerosBlock",
    TrailingZeros = "FlowGraphTrailingZerosBlock",
    OneBitsCounter = "FlowGraphOneBitsCounterBlock",
    Branch = "FlowGraphBranchBlock",
    SetDelay = "FlowGraphSetDelayBlock",
    CancelDelay = "FlowGraphCancelDelayBlock",
    CallCounter = "FlowGraphCallCounterBlock",
    Debounce = "FlowGraphDebounceBlock",
    Throttle = "FlowGraphThrottleBlock",
    DoN = "FlowGraphDoNBlock",
    FlipFlop = "FlowGraphFlipFlopBlock",
    ForLoop = "FlowGraphForLoopBlock",
    MultiGate = "FlowGraphMultiGateBlock",
    Sequence = "FlowGraphSequenceBlock",
    Switch = "FlowGraphSwitchBlock",
    WaitAll = "FlowGraphWaitAllBlock",
    WhileLoop = "FlowGraphWhileLoopBlock",
    ConsoleLog = "FlowGraphConsoleLogBlock",
    Conditional = "FlowGraphConditionalBlock",
    Constant = "FlowGraphConstantBlock",
    TransformCoordinatesSystem = "FlowGraphTransformCoordinatesSystemBlock",
    GetAsset = "FlowGraphGetAssetBlock",
    GetProperty = "FlowGraphGetPropertyBlock",
    SetProperty = "FlowGraphSetPropertyBlock",
    GetVariable = "FlowGraphGetVariableBlock",
    SetVariable = "FlowGraphSetVariableBlock",
    JsonPointerParser = "FlowGraphJsonPointerParserBlock",
    CombineVector2 = "FlowGraphCombineVector2Block",
    CombineVector3 = "FlowGraphCombineVector3Block",
    CombineVector4 = "FlowGraphCombineVector4Block",
    CombineMatrix = "FlowGraphCombineMatrixBlock",
    CombineMatrix2D = "FlowGraphCombineMatrix2DBlock",
    CombineMatrix3D = "FlowGraphCombineMatrix3DBlock",
    ExtractVector2 = "FlowGraphExtractVector2Block",
    ExtractVector3 = "FlowGraphExtractVector3Block",
    ExtractVector4 = "FlowGraphExtractVector4Block",
    ExtractMatrix = "FlowGraphExtractMatrixBlock",
    ExtractMatrix2D = "FlowGraphExtractMatrix2DBlock",
    ExtractMatrix3D = "FlowGraphExtractMatrix3DBlock",
    TransformVector = "FlowGraphTransformVectorBlock",
    TransformCoordinates = "FlowGraphTransformCoordinatesBlock",
    MatrixDecompose = "FlowGraphMatrixDecompose",
    MatrixCompose = "FlowGraphMatrixCompose",
    BooleanToFloat = "FlowGraphBooleanToFloat",
    BooleanToInt = "FlowGraphBooleanToInt",
    FloatToBoolean = "FlowGraphFloatToBoolean",
    IntToBoolean = "FlowGraphIntToBoolean",
    IntToFloat = "FlowGraphIntToFloat",
    FloatToInt = "FlowGraphFloatToInt",
    Easing = "FlowGraphEasingBlock",
    Context = "FlowGraphContextBlock",
    ArrayIndex = "FlowGraphArrayIndexBlock",
    CodeExecution = "FlowGraphCodeExecutionBlock",
    IndexOf = "FlowGraphIndexOfBlock",
    FunctionReference = "FlowGraphFunctionReference",
    BezierCurveEasing = "FlowGraphBezierCurveEasing",
    DataSwitch = "FlowGraphDataSwitchBlock",
}
