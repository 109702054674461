// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "gaussianSplattingVertexDeclaration";
const shader = `attribute vec2 position;uniform mat4 view;uniform mat4 projection;uniform mat4 world;uniform vec4 vEyePosition;`;
// Sideeffect
if (!ShaderStore.IncludesShadersStore[name]) {
    ShaderStore.IncludesShadersStore[name] = shader;
}
/** @internal */
export const gaussianSplattingVertexDeclaration = { name, shader };
